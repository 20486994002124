import React from 'react';
import './App.css';

const App = () => {
  return (
    <div>
      {"Sometimes I Dev :shrug:"}
    </div>
  );
}

export default App;
